
  import ScreenSize from '@/mixins/ScreenSize';
  import EventBus from '@/events/EventBus';
  import Events from '@/events/Events';
  import clearableTabIndex from '@/mixins/clearableTabIndex';
  import DialogsBaseSection from '@/components/sections/DialogsBaseSection.vue';
  import LoginOAuth from '@/mixins/LoginOAuth';
  import {hasRelevantDataForApplication} from '@/helper/ProfileCompletenessHelper';

  export default {
    name: 'LoginForm',
    components: {
      DialogsBaseSection,
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [clearableTabIndex, LoginOAuth, ScreenSize],
    props: {
      email: {
        type: String,
        required: false,
        default: '',
      },
      job: {
        type: Object,
        required: false,
        default: () => {},
      },
      position: {
        type: String,
        required: false,
        default: 'NULL',
      },
    },
    data() {
      return {
        loginData: {email: '', password: ''},
        loginItems: [],
        loginError: null,
        section: {},
        showPassword: false,
        buttonsMenu: false,
        disabled: false,
      };
    },
    async fetch() {
      const strapiSingleType = this.job
        ? 'registration-before-application-dialog'
        : 'registration-or-login-dialog';
      const section = await this.$cachedStrapi
        .find(strapiSingleType)
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
      this.section = {
        title: section.login_title,
        subline: section.login_subline,
        intro_text: section.login_intro_text,
        button_text: section.login_button_text,
        forget_password_link_text: section.login_forget_password_link_text,
        facebook_login: section.facebook,
        apple_login: section.apple,
        google_login: section.google,
        microsoft_login: section.microsoft,
      };
      this.loginData.email = this.email;
    },
    methods: {
      sendMatomoEvent(type) {
        if (type === 'facebook') {
          this.$emitWithClientData(
            this.job
              ? Events.RegistrationBeforeApplicationSwitchToFacebook
              : Events.RegistrationOrLoginSwitchToFacebook,
            {
              type: 'Login',
              jobPostingId: this.job?.id,
            }
          );
        } else if (type === 'google') {
          this.$emitWithClientData(
            this.job
              ? Events.RegistrationBeforeApplicationSwitchToGoogle
              : Events.RegistrationOrLoginSwitchToGoogle,
            {
              type: 'Login',
              jobPostingId: this.job?.id,
            }
          );
        } else if (type === 'apple') {
          this.$emitWithClientData(
            this.job
              ? Events.RegistrationBeforeApplicationSwitchToApple
              : Events.RegistrationOrLoginSwitchToApple,
            {
              type: 'Login',
              jobPostingId: this.job?.id,
            }
          );
        } else {
          this.$emitWithClientData(
            this.job
              ? Events.RegistrationBeforeApplicationSwitchToMicrosoft
              : Events.RegistrationOrLoginSwitchToMicrosoft,
            {
              type: 'Login',
              jobPostingId: this.job?.id,
            }
          );
        }
      },
      login() {
        this.disabled = true;
        this.$auth
          .loginWith('local', {data: this.loginData})
          .then(async () => {
            EventBus.$emit('login');
            this.$emit('close-dialog');
            this.resetDialog();

            this.$notifications.showNotification({
              message: this.$t('action.user_login'),
              color: 'success',
              timeout: 5000,
            });

            this.$store.dispatch(
              'profileCompleteness/calculateProfileCompleteness'
            );
            if (this.job) {
              this.$emitWithClientData(
                Events.RegistrationBeforeApplicationStepFinished,
                {
                  step: 'Login',
                  jobPostingId: this.job.id,
                }
              );
              this.$auth.fetchUser().then(() => {
                if (
                  hasRelevantDataForApplication(
                    this.$auth.user.candidateProfile
                  )
                ) {
                  this.$emitWithClientData(Events.JobApplicationOpened, {
                    jobPostingId: this.job.id,
                  });
                } else {
                  this.$emitWithClientData(Events.UpdateAndApplicationOpened, {
                    jobPostingId: this.job.id,
                  });
                }
              });
            } else {
              this.$emitWithClientData(Events.RegistrationOrLoginStepFinished, {
                step: 'Login',
              });
              this.$emitWithClientData(Events.RegistrationOrLoginFinished, {
                type: 'Login',
              });
            }
            this.$emitWithClientData(Events.LoggedIn, {
              type: this.job
                ? 'RegistrationBeforeApplication'
                : 'RegistrationOrLogin',
              channel: 'default',
            });
            if (this.job) {
              await this.$localforage.setItem('SavedApplyJob', this.job);
              this.$router.push(
                this.localePath({
                  name: this.$router.url,
                  query: {loginApply: 1},
                })
              );
            }
          })
          .catch((e) => {
            if (e.response === undefined || e.response.status === undefined) {
              throw e;
            }
            this.loginError = e.response.status;
          });
        setTimeout(() => (this.disabled = false), 3000);
      },
      async sendResetPasswordRequest() {
        const emailValidation = this.$refs.emailValidation;
        const validator = await emailValidation.validate();
        if (validator.valid === true) {
          this.$store
            .dispatch('api/candidateAccount/sendEmailForForgotPassword', {
              email: this.loginData.email,
            })
            .then((response) => {
              if (response.status === 200) {
                this.$notifications.showNotification({
                  message: this.$t('forgot.password.send_email_success'),
                  color: 'success',
                  timeout: 5000,
                });
                this.$emit('close-dialog', 'Login');
                this.$emitWithClientData(
                  this.job
                    ? Events.RegistrationBeforeApplicationForgetPassword
                    : Events.RegistrationOrLoginForgetPassword,
                  {
                    type: true,
                  }
                );
              }
            })
            .catch((e) => {
              if (e.response.status !== 400) {
                this.$notifications.showNotification({
                  message: this.$t('forgot.password.send_email_success'),
                  color: 'success',
                  timeout: 5000,
                });
                this.$emit('close-dialog', 'Login');
                throw e;
              }
              this.$notifications.showNotification({
                message: this.$t('forgot.password.send_email_warning'),
                color: 'warning',
                timeout: 5000,
              });
              this.$emitWithClientData(
                this.job
                  ? Events.RegistrationBeforeApplicationForgetPassword
                  : Events.RegistrationOrLoginForgetPassword,
                {
                  type: false,
                }
              );
            });
        } else {
          this.$notifications.showNotification({
            message: this.$t('forgot.password.missing_email_error'),
            color: 'error',
            timeout: 3000,
          });
        }
      },
      resetDialog() {
        this.loginData = {email: '', password: ''};
      },
    },
  };
