import {popupCenter} from '@/helper/PopupHelper';
import {
  prepareOAuthRedirectUrlWithUtmCookie,
  prepareUrl,
} from '@/helper/UrlHelper';
export default {
  methods: {
    appleLogin() {
      popupCenter({
        url: prepareUrl(
          process.env.NUXT_ENV_API_DOMAIN +
            process.env.NUXT_ENV_API_LOGIN_APPLE_ENDPOINT,
          {
            getParameters: {
              redirectUrl: prepareOAuthRedirectUrlWithUtmCookie(
                this.$cookies.get('utm')
              ),
            },
          }
        ),
        title: 'Apple Login',
        w: 400,
        h: 600,
      });
    },
    facebookLogin() {
      popupCenter({
        url: prepareUrl(
          process.env.NUXT_ENV_API_DOMAIN +
            process.env.NUXT_ENV_API_LOGIN_FACEBOOK_ENDPOINT,
          {
            getParameters: {
              redirectUrl: prepareOAuthRedirectUrlWithUtmCookie(
                this.$cookies.get('utm')
              ),
            },
          }
        ),
        title: 'Facebook Login',
        w: 400,
        h: 600,
      });
    },
    googleLogin() {
      popupCenter({
        url: prepareUrl(
          process.env.NUXT_ENV_API_DOMAIN +
            process.env.NUXT_ENV_API_LOGIN_GOOGLE_ENDPOINT,
          {
            getParameters: {
              redirectUrl: prepareOAuthRedirectUrlWithUtmCookie(
                this.$cookies.get('utm')
              ),
            },
          }
        ),
        title: 'Google Login',
        w: 400,
        h: 600,
      });
    },
    microsoftLogin() {
      popupCenter({
        url: prepareUrl(
          process.env.NUXT_ENV_API_DOMAIN +
            process.env.NUXT_ENV_API_LOGIN_MICROSOFT_ENDPOINT,
          {
            getParameters: {
              redirectUrl: prepareOAuthRedirectUrlWithUtmCookie(
                this.$cookies.get('utm')
              ),
            },
          }
        ),
        title: 'Microsoft Login',
        w: 400,
        h: 600,
      });
    },
  },
  computed: {
    socialLoginCols() {
      let num_buttons = 0;

      if (this.section.apple_login) {
        num_buttons++;
      }
      if (this.section.facebook_login) {
        num_buttons++;
      }
      if (this.section.google_login) {
        num_buttons++;
      }
      if (this.section.microsoft_login) {
        num_buttons++;
      }

      if (num_buttons === 0) {
        return false;
      } else if (num_buttons === 4) {
        return 6;
      } else return 12 / num_buttons;
    },
  },
};
